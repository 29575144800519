import { render, staticRenderFns } from "./personnelCreatePage.vue?vue&type=template&id=5346f19a&scoped=true&"
import script from "./personnelCreatePage.vue?vue&type=script&lang=js&"
export * from "./personnelCreatePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5346f19a",
  null
  
)

export default component.exports